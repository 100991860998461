@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
*{
    font-family: "Work Sans", sans-serif;
}
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    z-index: 9;
    opacity: .9;
    background: #D7DAE0;
    border-bottom: 1px solid #ddd;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    z-index: 1;
}
.header-container{
    display: flex;
    height: 75px;
    justify-content: space-between;
}
.header-content{
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-content ul{
    display: flex;
    color: #15234A;
    margin-bottom: 0rem;
    align-items: center;
}
.header-content ul li{
    margin: 0px 10px;
    list-style: none;
}
.header-content ul li a{
    text-decoration: none;
    font-weight: 500;
    color: #15234A;
}
.header-content ul li a:active{
    font-weight: 500;
}
.header-content img{
    width: 100px;
    top: 7px;
    position: absolute;
}
.call-btn{
    background: #15234A;
    padding: .5rem;
}
.call-btn a{
    color: #fff!important;
}


.checkbtn{
    font-size: 30px;
    color: #fff;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}



@media (max-width: 768px){
    .key-services-container {
        width: 100%!important;
        display: block!important;
    }
    .key-services-content-img {
        width: 100%!important;
        height: auto;
        min-height: 340px!important;
    }
    .key-services-content-text {
        width: 100%!important;     
    }
    .key-services-content-text {
        padding: 2rem 0rem!important;
    }
    .banner-cta-container {
        width: 100%!important;
    }
    .best-services-container {
        display: block!important;
    }
    .best-services-content {
        width: 100%!important;
    }
    .best-services-content-text {
        width: 100%!important;
    }
    .best-services-content-text {
        padding: 2rem 0rem!important;
    }
    .best-services-op1,
    .best-services-op2,
    .best-services-op3  {
        width: 100%!important;
        margin: 1rem 0rem!important;
    }
    .best-services-content-img {
        display: block!important;
    }
   
}


@media (max-width: 952px){
    .header-content ul li{
        margin: 0px 5px;
    }    
    .header-content {
        display: none;
    }
    
}

@media (max-width: 858px){

}

@media (max-width: 1168px){
    .header-content ul {
        padding-left: 0;
    }
    .header-content ul li {
        margin: 0px 10px;
        list-style: none;
        font-weight: 500;
        font-size: 14px;
    }
    .header-content ul li a{
        font-size: 14px;
    }

    .hero-text h1 {
        font-size: clamp(2.1rem, 3.5vw, 1.2rem)!important;;
    }
    .hero-text p {
        font-size: clamp(1.2rem, 2.5vw, 1rem)!important;;
    }

    .header-container {
        background: #2c3e50;
        height: 100vh;
        left: -100%;
        position: fixed;
        text-align: center;
        top: 75px;
        transition: all .5s;
        width: 100%;
    }
    .checkbtn{
        display: block;
    }
    #check:checked ~ .header-container{
        left:0;
    }
    #check:checked~.header-container {
        left: 0;
    }
    

  }



  @media (max-width: 420px){
    .hero-text {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
    }
    .hero-text h1 {
        font-size: clamp(2rem, 3.5vw, 1.2rem) !important;
    }
    .hero-text p {
        font-size: clamp(1rem, 2.5vw, 1rem) !important;
    }
}