.about{
    width: 100%;
    padding: 6rem 0rem;
    height: auto;
    min-height: 50vh;
    align-content: center;
}

.about-container{
    width: 100%;
    display: flex;
    margin-top: 4rem;
}
.about-option-list{
    width: 25%;
    max-width: 25%;  
}
.about-option{
    cursor: pointer;
    transition: color 0.3s ease;
    border-left: 3px solid #E5EBF5;
}
.about-option h3{
    font: 500 16px "Work Sans", sans-serif;
    margin-bottom: 0;
    padding: 1rem;
}
.about-option:after{
    border-bottom: 10px solid #0000;
    border-left: 12px solid #E5EBF5;
    border-top: 10px solid #0000;
    content: "";
    position: absolute;
    /*transform: translateY(-50%);*/
    transform: translateY(-175%);
}
.about-text{
    width: 75%;
}
.about-text h4{
    font-size: 20px;
    padding: 1rem 0rem;
}
.about h2{
    font-weight: 600;
}
.about p{
    margin: 1.2rem 0rem;
}
  
  /* Colores específicos para cada opción activa */
  .about-option.active1 {
    color: #8A91A480; /* Color para la opción Misión */
  }
  
  .about-option.active2 {
    color: #8A91A480; /* Color para la opción Visión */
  }
  
  .about-option.active3 {
    color: #8A91A480; /* Color para la opción Historia */
  }
  
