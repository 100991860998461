.hero {    
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../assets/images/hero.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 90vh;
}
.hero_content{
    align-items: center;
    display: grid;
    height: 95vh;
}
.hero-text{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.hero-text h1{
    font-size: clamp(3.2rem, 4.5vw, 2.2rem);
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}
.hero-text p{
    font-size: clamp(1.5rem, 2.5vw, 1rem);
    font-weight: 500;
    color: #fff;
    text-align: center;
}



