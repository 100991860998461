@import url('https://cdn-uicons.flaticon.com/2.6.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.6.0/uicons-solid-straight/css/uicons-solid-straight.css');
.contact{
    width: 100%;
    padding: 6rem 0rem;
    background: #E5EBF5;
}
.contact-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.contact-container h2{
    font-weight: 600;
}
.contact-container p{
    font-weight: 400;
}
.contact-content{
    width: 49%;
}
.info-contact-box{
    padding: 2rem 2rem 2rem 0rem;
}
.contact-container-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
}
.contact-content-box{
    width: calc(50% - 10px);
    display: flex;
    margin: .5rem 0rem;
}
.contact-icon {
    width: 40px;
    height: 40px;
    background: #15234A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.contact-icon i{
    color: #fff;
}
.contact-box{
    display: grid;   
    margin-left: .5rem;
}
.contact-title{
    color: #15234A;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
}
.contact-info{
    color: #15234A;
    font-weight: 500;
    font-size: 14px;
}

.formulario {
    background: #8A91A4;
    padding: 2rem;
    background: linear-gradient(#8A91A4, #4F5977);
    height: 100%;
}
.formulario h2, .formulario p{
    color: #fff;
}
.formulario .form-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.formulario input[type=email], .formulario input[type=text] {
    border: 1px solid #dbdbdb;
    height: 50px;
    margin-bottom: 1.5rem;
    padding: 4px 10px;
    width: 49%;
}
.formulario textarea {
    border: 1px solid #dbdbdb;
    margin-bottom: 1rem;
    padding: 4px 10px;
    width: 49%;
}
.formulario textarea{
    width: 100%;
}
.formulario textarea{
    min-height: 90px;
}
.btn_enviar_formulario_contact button{
    padding: 10px 35px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    background: #BC8861;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: none;
}
.btn_enviar_formulario_contact button i{
    display: flex;
    margin-left: 12px;
    font-size: 13px;
}
.box_form_contact{
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
    display: block;
    padding: 3rem;
    text-align: center;
    background: #e2f0fe;
}

@media (max-width: 768px){
    .contact-container {
        width: 100%;
        display: block;
    }
    .contact-content {
        width: 100%;
        margin-bottom: 1rem;
    }
  }