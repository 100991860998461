@import url('https://cdn-uicons.flaticon.com/2.6.0/uicons-solid-rounded/css/uicons-solid-rounded.css');

.highlight-services{
    display: flex;
    width: 100%;
    color: #15234A;
    font-weight: 500;
    padding: 6rem 0rem;
    background: #8A91A450;
}
.highlight-services h2{
  font-weight: 600;
}
.highlight-services-content{
  display: flex;
}
.highlight-services-boxes:nth-child(1){
  width: 40%;
}
.highlight-services-boxes:nth-child(2){
  width: 60%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas */
}
.highlight-services-box {
  align-content: center;
  display: flex;
  align-items: center;

}
.highlight-services-box i{
  background: #15234A;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  display: flex;
  width: 30px;
  height: 30px;
  color: #fff;
  margin-right: 18px;
  padding: 1rem;
}
.services{
    width: 100%;
    height: auto;
    margin-top: -200px;
    padding: 2rem 0rem;
    background: linear-gradient(to top, transparent, #8A91A4, transparent);
}


.services-container {
    display: flex;
    align-items: flex-end; /* Los divs se alinean desde la parte inferior */
    width: 100%;
    height: auto;
  }

  
  .box {
    width: 33%; /* Ancho de cada div */
    background-color: lightblue;
    margin: 0 10px; /* Espacio entre los divs */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /*box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
  }
  
  .left {
    min-height: 300px; /* Altura del div izquierdo */
    background: linear-gradient(#F4F4F4, #D3D3D5);
  }
  
  .middle {
    min-height: 335px; /* Altura del div del medio (más grande) */
    background: linear-gradient(#8A91A4, #4F5977);
  }
  
  .right {
    min-height: 300px; /* Altura del div derecho */
    background: linear-gradient(#E9DFD4, #D1C0AF);
  }


  .key-services{
    padding: 6rem 0rem;
  }
  .key-services-container{
    width: 100%;
    display: flex;  
    justify-content: space-between;  
  }
  .key-services-content-img{
    background-image: url('../assets//images//services.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 48%;
    height: auto;
  }
  .key-services-content-text{
    width: 45%;
    color: #15234A;
    font-weight: 500;
  }
  .key-services-content-text ul{
    padding-left: 0;
  }
  .key-services-content-text h2{
    font-weight: 600;
  }
  .key-services-content-text p{
    margin: 1.2rem 0rem;
  }
  .key-services-content-text ul li{
    list-style: none;
    margin: 10px 0rem;
  }
  .key-services-content-text button{
    background: #BC8861;
    border: none;
    padding: 12px 30px;
    color: #fff;
    font-weight: 500;
  }

  .banner-cta{
    /*background-image: url('../assets//images//banner.webp');*/
    background-image: linear-gradient( 90deg, #15234A, 50%, transparent), url('../assets//images//banner.webp');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    /*background: rgb(32,83,76);*/
    /*background: linear-gradient(90deg, #15234A 35%, transparent 100%);*/
    padding: 6rem 0rem;
  }

  .banner-cta-container{
    width: 100%;
    color: #fff;
    font-weight: 500;
  }
  .banner-cta-container h2{
    font-weight: 600;
    text-align: center;
    margin: 2rem 0rem;
  }
  .ports{
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .ports ul{
    margin-right: 3rem;
  }
  .ports ul li{
    list-style: none;
    margin: .8rem 0rem;

  }
  .ports ul li i{
    margin-right: .5rem;
  }
 

  .best-services{
    padding: 6rem 0rem;
    width: 100%;
    height: auto;
  }
  .best-services-container{
    display: flex;
  }
  .best-services-content {
    width: 60%;
  }
  .best-services-content h2{
    color: #15234A;
    font-weight: 600;
    padding-bottom: 1.5rem;
  }
  .best-services-content-text{
    width: 40%;
    color: #15234A;
    font-weight: 400;

    align-content: center;
    padding: 2rem;
  }
  .best-services-content-text h2{
    font-weight: 600;
    padding-bottom: 1rem;
  }
  .best-services-content-img{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .best-services-op1{
    background-image: url('../assets/images/best-service1.jpg');
    width: 32%;
    min-height: 290px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }
  .best-services-op2{
    background-image: url('../assets/images/best-service2.jpg');
    width: 32%;
    min-height: 290px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }
  .best-services-op3 {
    background-image: url('../assets/images/best-service3.jpg');
    width: 32%;
    min-height: 290px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }
  .best-services-op1 button,
  .best-services-op2 button,  
  .best-services-op3 button{
    align-self: flex-end;
    display: flex;
    
    background: #A6612D;
    border: none;
    padding: 12px 30px;
    color: #fff;
    font-weight: 500;
    align-items: center;
  }

  .best-services-op1 i,
  .best-services-op2 i,  
  .best-services-op3 i{
    display: flex;   
    margin-left: 5px;
  }

.faq-item{
    margin: 10px 0rem;
  }
.icon_faq{
    display: flex;
    align-items: center;
}
.icon_faq i{
    display: flex;
    margin-right: 5px;
}
.question{
    display: flex;
    cursor: pointer;
}

  
.answer-enter {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
}
  
.answer-enter-active {
	opacity: 1;
	max-height: 100px;
	transition: max-height 300ms, opacity 300ms;
}
  
.answer-exit {
	opacity: 1;
	max-height: 100px;
}
  
.answer-exit-active {
	opacity: 0;
	max-height: 0;
	transition: max-height 300ms, opacity 300ms;
}
  
.answer {
	overflow: hidden;
	margin-top: 1rem;
}

@media (max-width: 1168px){
  .header-content ul li {
    margin: 0px 10px;
    list-style: none;
    font-weight: 500;
}
}

@media (max-width: 952px){
    .services-container {
        display: block;
    }
    .box {
        width: 100%;
        margin: 1.5rem 0rem;
    }
}
@media (max-width: 768px){
  .contact-container {
      width: 100%;
      display: block;
  }
  .highlight-services-content {
    display: grid;
}
.highlight-services-boxes:nth-child(1),
.highlight-services-boxes:nth-child(2) {
    width: 100%;
    margin-bottom: 1rem;
  }
  .ports {
    display: grid;
}
}