.footer{
    width: 100%;
    padding-top: 6rem;
    background-color: #15234A;
    
}
.footer_content{
    width: 100%;
    display: flex;
    margin-bottom: 3rem;
}

.footer_main_box img{
    /*width: 200px;*/
    width: 100%;
}
.footer_main_box p{
    font-size: 16px;
    /*color: #7C7C7C;*/
    color: #fcfcfc;
    font-weight: 400;
    margin-bottom: 0rem;
}
.footer_container{
    display: flex;
    width: 75%;
    justify-content: space-around;
}
.footer_main_box{
    width: 25%;
    align-content: space-between;
    display: grid;
}
.footer_main_box img {
    /* width: 200px; */
    align-self: center;
    width: 35%;
}
.footer_box {
    align-content: center;
}
.footer_box li{
    padding: .5rem 0rem;
}
.footer_box li a{
    text-decoration: none;
}

.footer_box li h3{
    /*color: #747474;*/
    color: #fff;
    font-weight: 500;
    margin-bottom: .5rem;
    font-size: 20px;
}

.footer_box li a{
    font-size: 16px;
    /*color: #7C7C7C;*/
    color: #fcfcfc;
    font-weight: 400;
}
.footer_copyright{
    background-color: #2C3659;
    padding: 1.2rem 0rem;
    text-align: center;
    color: #fff;
}
.footer_copyright label{
    margin-right: 5px;
    font-size: 16px;
}

@media (max-width: 768px){
    .footer_content{
        display: block;
    }  
    .footer_main_box{
        width: 100%;
    }
    .footer_container{
        width: 100%;
        display: block;
    }
    .footer_box{
        width: 100%;
    }
    .footer_box ul{
        margin-left: 0rem;
        padding-left: 0rem;
    }
    .footer_copyright label{
        font-size: 14px;
    }
}